import React from 'react';
import HeaderBar from './header-bar/header-bar';
import NavigationMenu from './sidebar/navigation-menu';
import Badge from './containers/Badge';

import LawsuitService from '../services/LawsuitService';
import Select from 'react-select';

import tribunalTypes from './json/tribunalTypes';

import TribunalService from '../services/TribunalService';
import AuthService from '../services/AuthService';

import { Link } from 'react-router-dom';
import 'antd/dist/antd.css';
import { Tabs } from 'antd';
import { Divider } from 'antd';
import Popover, { ArrowContainer } from 'react-tiny-popover';

import Footer from "./containers/footer";
import FlashMessage from "./containers/FlashMessage";
import MyPopover from './containers/mypopover';

import Loading from './containers/loading';
import Alert from './containers/alert';
import Confirm from './containers/confirm';

import img_finance from '../image/img_finance.svg';

export default class Finance extends React.Component { 
  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
    document.body.classList.remove('gestaoprev_private');
  }

  componentDidMount( ) { 
        this._isMounted = true;  
        document.body.classList.add('gestaoprev_private');

  }

  constructor( { match } ) {
      super();
      this.lawsuitService = new LawsuitService();
      this.authService = new AuthService();

      document.body.classList.add('gestaoprev_private');

      this.back = this.back.bind(this);

  }

  componentWillMount( ) {

  }


  state = {
    value: ''
  }

  back(){
    this.props.history.goBack();
  }

  render() {

      let url_base = localStorage.getItem('url_base');
      const loggedUser = JSON.parse(localStorage.getItem('loggedUser'));


      return <div className="sheet">
                <FlashMessage time={ new Date().getTime() } />
                <HeaderBar />
                <NavigationMenu />
                <div className="content-wrapper">
                  <h1>Financeiro</h1>
                  <div className='screen-empty'>
                      <object type="image/svg+xml" data={img_finance} width="260" height="260"></object>
                      <span className='subtitle'>Cuide do coração da sua empresa e faça a gestão financeira de todo o seu escritório</span>
                      <span>Tenha acesso ao fluxo de caixa, contas a pagar, contas a receber e filtros rápidos</span>
                      <span className="btn btn-primary form-submit btn-new-add-lawsuit" data-toggle="dropdown">
                            ADICIONAR LANÇAMENTO
                      </span>
                      <ul className="dropdown-menu dropdown-new-lawsuit dropdown">
                          <li >
                              <div>
                                  <Link to={"/perfil"}>
                                      Entrada
                                  </Link>
                              </div>
                          </li>
                          <li >
                              <div>
                                  <Link to={"/perfil"}>
                                      Saída
                                  </Link>
                              </div>
                          </li>
                          <li >
                              <div>
                                  <Link to={"/perfil"}>
                                      Transferência
                                  </Link>
                              </div>
                          </li>
                      </ul>
                  </div>
                </div>
             </div>
  }

}