import React from 'react';
import Footer from "./containers/footer";
import Alert from './containers/alert';
import { Link } from 'react-router-dom';
import FlashMessage from "./containers/FlashMessage";

import AuthService from '../services/AuthService';
import OfficeService from '../services/OfficeService';
import './Login.css';
import logo from '../image/logo.svg';
import img_login_page from '../image/login.svg';

export default class Login extends React.Component { 
  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
    document.body.classList.remove('login');
  }


  constructor() {
      super();
      this.handleChange = this.handleChange.bind(this);
      this.handleFormSubmit = this.handleFormSubmit.bind(this);
      this.Auth = new AuthService();
      this.OfficeService = new OfficeService();

      this.state = {
        message: "",
        showAlert: false
      }

      document.body.classList.add('login');

      console.log("passamos no construtor");

      window.scrollTo(0,0);

  }

  componentWillMount(){
    if(this.Auth.loggedIn())
        this.props.history.replace('/');
  }

  componentDidMount(){
    var url_string = window.location.href;
    var url = new URL(url_string);
    var token = url.searchParams.get("token");
    document.body.classList.add('login');

    document.addEventListener("cancelAlert", (e) => {
        this.setState({
        showAlert: false
        });
    });

    var confirm_token = url.searchParams.get("confirm_token");

    if( token !== null){
        this.OfficeService.confirm(token).then(res =>{
              if( res.success === true ){
                  localStorage.setItem('alert_message', res.message);
                  localStorage.setItem('alert_type', 'alert-success');
                  let time = new Date().getTime();
                  localStorage.setItem('alert_time', time );

                  setTimeout(() => {

                    this.setState ({
                        update: 1
                    });

                  }, 300);

              }else{
                localStorage.setItem('alert_message', res.message);
                localStorage.setItem('alert_type', 'alert-danger');
                let time = new Date().getTime();
                localStorage.setItem('alert_time', time );
                setTimeout(() => {

                  this.setState ({
                      update: 2
                  });

                }, 300);
              }
          });
    }

    if( confirm_token !== null){
      this.OfficeService.confirm(confirm_token).then(res =>{
            if( res.success === true ){
                localStorage.setItem('alert_message', res.message);
                localStorage.setItem('alert_type', 'alert-success');
                let time = new Date().getTime();
                localStorage.setItem('alert_time', time );

                setTimeout(() => {

                  this.setState ({
                      update: 1
                  });

                }, 300);

            }else{
              localStorage.setItem('alert_message', res.message);
              localStorage.setItem('alert_type', 'alert-danger');
              let time = new Date().getTime();
              localStorage.setItem('alert_time', time );
              setTimeout(() => {

                this.setState ({
                    update: 2
                });

              }, 300);
            }
        });
  }

  }

  handleFormSubmit(e){
       e.preventDefault();
       document.body.style.cursor = "wait";
       document.getElementById("submit").disabled = true;

       let delay = 500+Math.random()*500;

       setTimeout(() => {
        this.Auth.login(this.state.email,this.state.password)
        .then(res =>{
           if(res === "success"){
             setTimeout(() => {                  
                 document.body.style.cursor = "default";
                 window.location.href = '/';
             }, 60);
             //this.props.history.replace('/');
           }else{
             document.body.style.cursor = "default";
           }
           document.getElementById("submit").disabled = false;
        })
        .catch(err =>{
            alert(err);
            /*this.setState({
             showAlert: true,
             message: err
            });*/

           if(this._isMounted){
             document.body.style.cursor = "default";
             document.getElementById("submit").disabled = false;
           }
        })        
       }, delay);


   }

   handleChange(e){
        this.setState(
            {
                [e.target.name]: e.target.value
            }
        )
    }


  render() {

      var url_base = localStorage.getItem('url_base');

      console.log("estamos fazendo login");

      //<span className="alert alert-danger"><center>Estamos com uma instabilidade no servidor e trabalhando para resolvê-la. Desculpe-nos o transtorno.</center></span>

      return <div id="login">
                  <FlashMessage time={ new Date().getTime() } />
                  <Alert show={this.state.showAlert} text={this.state.message} />
                  <div className="bg page-login">
                      <div className="left-panel">
                          <a className="logo" href="/">
                            <img src={logo} width="43" height="48"></img>
                            <img src={url_base+"/images/gestaoprevi.svg"} width="234" height="62"></img>
                          </a>
                          <span className="login-title">O Número #1 dos</span>
                          <span className="login-title">Advogados Previdenciários</span>
                          <object type="image/svg+xml" data={img_login_page} width="424" height="283"></object>
                      </div>
                      <div className="form-login">
                          <form>
                              <h1>Acesse sua conta</h1>
                              <span>Não tem uma conta?<Link className="click-here" to="signup">Clique aqui</Link>para criar </span>
                              <label htmlFor="email" className="">E-mail</label>
                              <input
                                  className="form-control form-item"
                                  placeholder="Digite seu e-mail"
                                  name="email"
                                  type="text"
                                  onChange={this.handleChange}
                              />
                              <label htmlFor="password">Senha</label>
                              <input
                                  className="form-control form-item"
                                  placeholder="Insira sua senha"
                                  name="password"
                                  type="password"
                                  onChange={this.handleChange}
                              />
                              <div className="isoInputWrapper isoLeftRightComponent">
                                    <input
                                        id= "submit"
                                        className="btn btn-primary form-submit"
                                        value="Entrar"
                                        type="submit"
                                        onClick={this.handleFormSubmit.bind(this)}
                                    />
                              </div>
                              
                              <span className="center">Esqueceu sua senha? <Link className="click-here" to="forgotPassword"> Clique aqui </Link> para recuperar</span>
                          </form>
                      </div>
                  </div>
             </div>
  }

}
