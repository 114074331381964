import React from 'react';
import Alert from '../containers/alert';
import InputMask from 'react-input-mask';
import Select from 'react-select';
import { Link } from 'react-router-dom';

import Loading from '../containers/loading';
import FlashMessage from '../containers/FlashMessage';

import OfficeService from '../../services/OfficeService';
import PublicationsService from '../../services/PublicationsService';
import AddressService from '../../services/AddressService';
import TribunalService from '../../services/TribunalService';
import LawsuitService from '../../services/LawsuitService';
import AuthService from '../../services/AuthService';

import { Progress } from 'antd';

export default class ConfigOffice extends React.Component { 
  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }

  constructor( props ) {
    super(props);

    this.authService = new AuthService();

    this.handleChange = this.handleChange.bind(this);
    this.selectChange = this.selectChange.bind(this);
    this.update = this.update.bind(this);
    this.getCEP = this.getCEP.bind(this);

    this.state = {
      nome: "",
      nome_fantasia: "",
      expiration: "",
      plan: 0,
      actives: 0,
      manuals: 0,
      archived: 0,
      percent: 0,
      ged_percent: 0,
      ged: 0,
      ged_utilized: 0,
      cep: "",
      rua: "",
      numero: "",
      bairro: "",
      cidade: "",
      opt_estado: "",
      complemento: "",
      email: "",
      total_manuals_count: 0,
      loadingVisible: false,
      loadingMessage: [],
      message: "",
      showAlert: false,
      free: "",
      required: false,
      phone: "",
      errors: {},
      formIsHalfFilledOut: false
    }

    window.scrollTo(0,0);

    if( !this.authService.can('acesso_configuracoes', true) ){
      this.props.history.replace('/');
    }

    let url_string = window.location.href;
    let url = new URL(url_string);
    let incomplete = url.searchParams.get("preencher_info");
    //console.log(incomplete); 
    //console.log(movements);
    if( incomplete !== null ){
      this.state.showAlert = true;
      this.state.required = true;
      this.state.formIsHalfFilledOut = true;
      this.state.message = "Seus dados estão incompletos, por favor preencha!";
      //alert("Seu plano venceu, renove agora!");
    }

  }

  componentDidMount( ) { 
    this._isMounted = true; let plan = 0;

    document.addEventListener("cancelAlert", (e) => {
        if( this._isMounted ){
          this.setState({
            showAlert: false
          });
        }
    });

    document.addEventListener("cancelLoading", (e) => {
      this.setState({
        loadingVisible: false
      });
    });

    this.OfficeService = new OfficeService();
    this.AddressService = new AddressService();
    this.PublicationsService = new PublicationsService();

    let token = JSON.parse(localStorage.getItem('loggedUser')).office_token;

    let qtd_ativos = 0;
    let qtd_arquivados = 0;

    this.OfficeService.getConfigs( token ).then(response =>{
      console.log('get Settings', response);

          if (this._isMounted) {

            let formIsHalfFilledOut = false;

            if( 
                (response.cep === null || response.cep === "") ||
                (response.city_id === null || response.city_id === "") ||
                (response.number === null || response.number === "") ||
                (response.neighborhood === null || response.neighborhood === "") ||
                (response.phone === null || response.phone === "") ||
                (response.state_id === null || response.state_id === "") ||
                (response.street === null || response.street === "") 
            ){
              formIsHalfFilledOut = true;
            }

            this.setState({
              nome: response.name,
              nome_fantasia: (response.name_fantasy !== null)?response.name_fantasy : "",
              rua: (response.street !== null)?response.street : "",
              numero: (response.number !== null)?response.number : "",
              complemento: (response.complement !== null)?response.complement : "",
              bairro: (response.neighborhood !== null)?response.neighborhood : "",
              cep: (response.cep !== null) ? response.cep : "",
              email: (response.email !== null)?response.email : "",
              phone: (response.phone !== null)?response.phone : "",
              formIsHalfFilledOut
            });

            this.AddressService.getEstado( ).then(res =>{

                  if (this._isMounted) {
                        let opt_estados = res;
                        let estado_id = ( response.state_id !== null ) ? response.state_id : '2';
                        let personage_uf = "";
                        let personage_city = "";

                        this.AddressService.getCidades( estado_id ).then( cities=>{
                          if (this._isMounted) {

                                //console.log(response);

                                if ( response.state_id !== null ) {
                                  personage_uf = opt_estados.filter( ( estado )=>{  return estado.value === response.state_id  } );
                                  personage_city = cities.filter( ( cidade )=>{  return cidade.value === response.city_id  } );
                                }

                                this.setState({
                                  opt_estados: opt_estados,
                                  cities: cities,
                                  opt_estado: personage_uf,
                                  cidade: personage_city,
                                });

                          }

                        });
                  }

            });

          }

    });

    let getParentAnchor =  (element) => {
      while (element !== null) {
        if (typeof element.tagName !== "undefined" && element.tagName.toUpperCase() === "A" && element.href.indexOf("#") === -1  ) {
          //console.log(element.href);
          return element;
        }
        element = element.parentNode;
      }
      return null;
    };

  }

  handleChange(e){

       this.setState(
           {
               [e.target.name]: e.target.value
           }
       )
   }

   selectChange = (selected, actionMeta) => {
     let pops = [];
     pops[ actionMeta.name ] = selected;
     if ( typeof actionMeta.name !== undefined && actionMeta.name !== "" && actionMeta.name !== null ){

       if ( typeof selected !== undefined && selected !== "" && selected !== null ) {
         this.setState( pops );
       }

     }
   }

   validateForm = () => {

      let errors = {};
      let formIsValid = true;
      let message = "";

      if( this.state.required ){

        if( this.state.rua === 0 || this.state.rua === "" || typeof this.state.rua === "undefined" ){
          formIsValid = false;
          errors["rua"] = "*Por favor preencha a rua";
        }

        if( this.state.cep === 0 || this.state.cep === "" || typeof this.state.cep === "undefined" ){
          formIsValid = false;
          errors["cep"] = "*Por favor preencha o CEP";
        }

        if( this.state.numero === 0 || this.state.numero === "" || typeof this.state.numero === "undefined" ){
          formIsValid = false;
          errors["numero"] = "*Por favor preencha o número";
        }

        if( this.state.bairro === 0 || this.state.bairro === "" || typeof this.state.bairro === "undefined" ){
          formIsValid = false;
          errors["bairro"] = "*Por favor preencha o bairro";
        }

        if( this.state.phone === 0 || this.state.phone === "" || typeof this.state.phone === "undefined" ){
          formIsValid = false;
          errors["phone"] = "*Por favor preencha o telefone";
        }

        if( this.state.opt_estado === 0 || this.state.opt_estado === "" || typeof this.state.opt_estado === "undefined" ){
          formIsValid = false;
          errors["opt_estado"] = "*Por favor preencha o estado";
        }

        if( this.state.cidade === 0 || this.state.cidade === "" || typeof this.state.cidade === "undefined" ){
          formIsValid = false;
          errors["cidade"] = "*Por favor preencha a cidade";
        }

        this.setState({
          errors: errors,
        });

        return formIsValid;

      }else{
        return true;
      }

    }

   update = (event) => {

      if ( this.validateForm() ) {

          this.setState({
            loadingVisible: true,
            loadingMessage: ["As alterações estão sendo salvas.", "Por gentileza, aguarde...", "Tempo estimado de 05 a 15 segundos"]
          });
   
          this.OfficeService = new OfficeService();
          let token = JSON.parse(localStorage.getItem('loggedUser')).office_token;
          let estado = ""; let cidade = "";

         //console.log(this.state.opt_estado);
         //console.log(this.state.cidade);
         //console.log(this.state.rua);

          if( Array.isArray(this.state.opt_estado) ){
            estado = this.state.opt_estado[0].value;
          }else{
            estado = this.state.opt_estado.value;
          }

          if( Array.isArray(this.state.cidade) ){
            cidade = this.state.cidade[0].value;
          }else{
            cidade = this.state.cidade.value;
          }
   
          let dados = {
            //name: this.state.nome,
            name_fantasy: this.state.nome_fantasia,
            state_id: estado,
            city_id: cidade,
            street: this.state.rua,
            number: this.state.numero,
            complement: this.state.complemento,
            neighborhood: this.state.bairro,
            cep: this.state.cep,
            phone: this.state.phone,
            //email: this.state.email
          };
   
          this.OfficeService.update( token, dados ).then(response =>{
   
               if (this._isMounted) {
   
                     if( response.success === true ){
                         localStorage.setItem('alert_message', "Informações atualizadas com sucesso");
                         localStorage.setItem('alert_type', 'alert-success');
                         let time = new Date().getTime();
                         localStorage.setItem('alert_time', time );
                         setTimeout(() => {
                           this.setState({
                             updated: time,
                             required: false,
                             formIsHalfFilledOut: false
                           });
                         }, 300);
                         //alert("Comentário adicionado com sucesso");
                     }
   
                     this.setState({
                       loadingVisible: false,
                     });
   
               }
   
          });

      }

   }

   stateChange = (selected, actionMeta) => {
     let pops = [];
     pops[ actionMeta.name ] = selected;
    //console.log(selected);

     if ( typeof actionMeta.name !== undefined && actionMeta.name !== "" && actionMeta.name !== null ){

       this.AddressService.getCidades( selected.value ).then( cities=>{
            if (this._isMounted) {
                this.setState({
                  cities: cities
                });
            }
       });

       if ( typeof selected !== undefined && selected !== "" && selected !== null ) {
         this.setState( pops );
       }

     }
   }

   getCEP(e){
        let cep = e.target.value.replace(/\D+/g, '');
       if (e.keyCode === 13 || cep.length === 8) {
        this.AddressService.getCEP( this.state.cep.replace(".", "").replace("-","") ).then(res =>{

            if (this._isMounted) {
                  //console.log(res);69905074

                  if ( res !== null ){

                        this.AddressService.getCidades( res.state_id ).then( cities=>{

                              if (this._isMounted) {

                                      this.setState(
                                          {
                                              'rua': res.rua,
                                              'bairro': res.bairro,
                                              'cities': cities,
                                              'cidade': cities.filter( ( cidade )=>{ return cidade.value === res.city_id } ),
                                              'opt_estado': this.state.opt_estados.filter( ( ufs )=>{ return ufs.uf === res.uf } )
                                          }
                                      );

                              }

                        });

                  }else{
                    //window.alert("CEP não encontrado, preencha manualmente os campos de rua, bairro, cidade e estado");
                    this.setState({
                      showAlert: true,
                      message: "CEP não encontrado, preencha manualmente os campos de rua, bairro, cidade e estado"
                    });
                  }

            }

        });            
       }
   }

  render() {

      return <div className="form-office form">
                <h2>Escritório</h2>
                <div className="box-body">
                  <div className="form-group col-md-6">
                    <label htmlFor="nome" className="">Nome</label>
                    <input className="form-control form-item" placeholder="" name="nome" id="nome" value={this.state.nome} type="text" onChange={this.handleChange} autoComplete="off" />
                    <div className="errorMsg">{this.state.errors.nome}</div>
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="cpfCnpj" className="">CPF/CNPJ</label>
                    <input className="form-control form-item" placeholder="" name="cpfCnpj" id="cpfCnpj" value={this.state.cpf} type="text" onChange={this.handleChange} autoComplete="off" />
                    <div className="errorMsg">{this.state.errors.cpfCnpj}</div>
                  </div>

                  <div className="form-group col-md-12">
                    <label htmlFor="email" className="">E-mail</label>
                    <input className="form-control form-item" placeholder="" name="email" id="email" value={this.state.email} autoComplete="new-password" type="email" onChange={this.handleChange} />
                    <div className="errorMsg">{this.state.errors.email}</div>
                  </div>

                  <div className="form-group col-md-12">
                    <label htmlFor="site" className="">Site</label>
                    <input className="form-control form-item" placeholder="" name="site" id="site" value={this.state.site} autoComplete="off" type="text" onChange={this.handleChange} />
                    <div className="errorMsg">{this.state.errors.site}</div>
                  </div>

                  <div className="form-group col-md-6">
                    <label htmlFor="phone" className="">Telefone</label>
                    <InputMask mask="(99)99999-9999" maskChar="_" className="form-control form-item" placeholder="" name="phone" id="phone" value={this.state.phone} autoComplete="new-password" type="text" onChange={this.handleChange} />
                    <div className="errorMsg">{this.state.errors.phone}</div>
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="phone2" className="">Telefone Opcional</label>
                    <InputMask mask="(99)99999-9999" maskChar="_" className="form-control form-item" placeholder="" name="phone2" id="phone2" value={this.state.phone2} autoComplete="new-password" type="text" onChange={this.handleChange} />
                    <div className="errorMsg">{this.state.errors.phone2}</div>
                  </div>

                  <div className="form-group col-md-12">
                    <label htmlFor="cep" className="">Endereço</label>
                    <InputMask mask="99.999-999" maskChar="_" className="form-control form-item" placeholder="Digite seu CEP" 
                               name="cep" id="cep" value={this.state.cep} autoComplete="new-password" type="text" 
                               onChange={this.handleChange} onKeyUp={this.getCEP}
                    />
                    <div className="errorMsg">{this.state.errors.cep}</div>
                  </div>
                  <div className="form-group col-md-6">
                    <input className="form-control form-item" placeholder="Digite seu Endereço e nº" name="rua" value={this.state.rua} id="rua" autoComplete="off" type="text" onChange={this.handleChange} />
                    <div className="errorMsg">{this.state.errors.rua}</div>
                  </div>
                  <div className="form-group col-md-6">
                    <input className="form-control form-item" placeholder="Complemento" name="complemento" value={this.state.complemento} id="inp_complemento" type="text" onChange={this.handleChange} />
                    <div className="errorMsg">{this.state.errors.complemento}</div>
                  </div>
                  <div className="form-group col-md-6">
                    <Select
                      className="opt_estado gestao-prev-select"
                      classNamePrefix="opt_estado gestao-prev-select"
                      name="opt_estado"
                      value={this.state.opt_estado}
                      options={this.state.opt_estados}
                      placeholder="Selecione ou digite seu estado"
                      onChange={this.stateChange}
                    />
                    <div className="errorMsg">{this.state.errors.opt_estado}</div>
                  </div>
                  <div className="form-group col-md-6">
                    <Select
                      className="cidades gestao-prev-select"
                      classNamePrefix="gestao-prev-select"
                      name="cidade"
                      value={this.state.cidade}
                      options={this.state.cities}
                      placeholder="Selecione ou digite sua cidade"
                      onChange={this.selectChange}
                    />
                    <div className="errorMsg">{this.state.errors.cidade}</div>
                  </div>
                  <div className='separator'></div>
                  <div className="button-container">
                        <Link className="btn-simple" to="/">
                        Cancelar
                        </Link>
                        <span id= "submit" className="btn btn-primary form-submit" onClick={this.update}>
                          Salvar
                        </span>
                  </div>
                </div>
             </div>
  }

}
