import React from 'react';
import options from './json/menu_options';
import tribunal_options from './json/tribunal_options';
import { Link } from 'react-router-dom';

import TribunalService from '../services/TribunalService';
import LawsuitService from '../services/LawsuitService';
import AuthService from '../services/AuthService';
import OfficeService from '../services/OfficeService';

import HeaderBar from './header-bar/header-bar';
import NavigationMenu from './sidebar/navigation-menu';
import Footer from "./containers/footer";
import TopCharts from './containers/topCharts';
import FlashMessage from './containers/FlashMessage';
import Alert from './containers/alert';
import { momentLocalizer } from 'react-big-calendar';
import moment from "moment";

export default class Home extends React.Component { 
  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
    document.body.classList.remove('gestaoprev_private');
  }


  constructor( { match } ) {
      super();

      this.authService = new AuthService();

      document.body.classList.add('gestaoprev_private');

      let total_tribunals_count = JSON.parse(localStorage.getItem('total_tribunals_count'));
      let total_manuals_count = JSON.parse(localStorage.getItem('total_manuals_count'));
      let total_inss_count = JSON.parse(localStorage.getItem('total_inss_count'));

      if ( typeof total_manuals_count === undefined || total_manuals_count === null || total_manuals_count === "" ){
        total_manuals_count = 0;
      }else{
        total_manuals_count = total_manuals_count.total;
      }
      if ( typeof total_tribunals_count === undefined || total_tribunals_count === null || total_tribunals_count === "" ){
        total_tribunals_count = 0;
      }else{
        total_tribunals_count = total_tribunals_count.total;
      }
      if ( typeof total_inss_count === undefined || total_inss_count === null || total_inss_count === "" ){
        total_inss_count = 0;
      }else{
        total_inss_count = total_inss_count.total;
      }


      let total_tribunals_count_movements = JSON.parse(localStorage.getItem('total_tribunals_count_movements'));
      if ( typeof total_tribunals_count_movements === undefined || total_tribunals_count_movements === null || total_tribunals_count_movements === "" ){
        total_tribunals_count_movements = 0;
      }else{
        total_tribunals_count_movements = total_tribunals_count_movements.total;
      }
      let total_tribunals_count_favorites_movements = JSON.parse(localStorage.getItem('total_tribunals_count_favorites_movements'));
      if ( typeof total_tribunals_count_favorites_movements === undefined || total_tribunals_count_favorites_movements === null || total_tribunals_count_favorites_movements === "" ){
        total_tribunals_count_favorites_movements = 0;
      }else{
        total_tribunals_count_favorites_movements = total_tribunals_count_favorites_movements.total;
      }
      this.state = {
        'active': (typeof match.params.active !== undefined )? match.params.active : 0,
        'total_manuals_count': total_manuals_count,
        'total_tribunals_count': total_tribunals_count,
        'total_tribunals_count_movements': ( this.authService.can('avisos_de_andamentos', true) ) ? total_tribunals_count_movements : 0,
        'total_tribunals_count_favorites_movements': ( this.authService.can('avisos_de_andamentos', true) ) ? total_tribunals_count_favorites_movements : 0,
        total_inss_count,
        total_inss_movements: 0,
        total_inss_count_favorites_movements: 0,
        manualLawsuitsRaw: [],
        lawsuitListRaw: [],
        inssListRaw: []
      }

      /*const loggedUser = JSON.parse(localStorage.getItem('loggedUser'));
      let showPublicationNotice = localStorage.getItem('showPublicationNotice');

      if( typeof loggedUser !== "undefined" && loggedUser !== null && loggedUser.admin && !showPublicationNotice ){
        this.state.changePlanLink = localStorage.getItem('url_base')+'/config/mudar-plano';
        this.state.alertTextButton = "Clique aqui!";
  
        this.state.message= "Atenção: Agora temos disponível para contratação de Publicações a nível nacional. Clique no botão e contrate!";
        this.state.showAlert = true;

        localStorage.setItem('showPublicationNotice', true);
      }else{
        this.state.message = "";
        this.state.showAlert = false;
        this.state.changePlanLink = null;
      }*/

      const loggedUser = JSON.parse(localStorage.getItem('loggedUser'));
      let showNotice = localStorage.getItem('showNewsNotice');

     //console.log(moment().diff( moment('2022-02-28'), 'days', true));

      if( /*typeof loggedUser !== "undefined" && loggedUser !== null && loggedUser.admin &&*/ !showNotice && moment().diff( moment('2022-08-28'), 'days', true) <=0  ){
  
        this.state.message= "ATENÇÃO à esta novidade, usuário do Avvocato \n\n"+

        "        A partir de agora. todas as melhorias, implementações e novas ferramentas serão informadas na aba “Novidades”, assim que forem implementadas no Avvocato."+
                
        " \n\n       Visualize esta aba de novidades ao lado da Pesquisa, na parte superior. Você será avisado quando for incluído uma ou mais novidades, assim que fizer login no sistema."+
                
        "\n\n        Avvocato, trabalhando para melhorar o seu trabalho! \n\n"+
                
        "\n\n    Qualquer dúvida, nos contate pelo nosso chat.\n\n"+
                
        "        Atenciosamente, \n"+
        "        Equipe Avvocato.";
        this.state.showAlert = true;

        localStorage.setItem('showNewsNotice', true);
      }else{
        this.state.message = "";
        this.state.showAlert = false;
        this.state.changePlanLink = null;
      }

      window.scrollTo(0,0);

  }

  componentWillReceiveProps(nextProps) {
    //console.log(nextProps.match.params.active)

    this.setState({
        'active': (typeof nextProps.match.params.active !== undefined )? nextProps.match.params.active : 0,
    });

  }

  componentDidMount( ) {
        this._isMounted = true;
        document.body.classList.add('gestaoprev_private');

        this.authService.setConfigs();

  }

  render() {/*link={this.state.changePlanLink}*/ /*textLink={this.state.alertTextButton}*/
    var escritorio_virtual = localStorage.getItem('escritorio_virtual' );
    if(  escritorio_virtual === false || escritorio_virtual === 0 || escritorio_virtual === "undefined" || escritorio_virtual === "false" ){
      this.props.history.push( '/afiliados' );
    }

      return <div className={(typeof this.state.active === "undefined")? "home sheet" : "sheet"}>
                <FlashMessage time={ new Date().getTime() } />
                <HeaderBar screen={ "Home" } active={ this.state.active } />
                <Alert show={this.state.showAlert} text={this.state.message}  />
                <NavigationMenu active={ this.state.active } />
                <div className="content-wrapper">

                  <h1>Dashboard</h1>
                  <TopCharts finance={true} active={ this.state.active } history={this.props.history} />
                </div>
             </div>
  }

}
