import React from 'react';
import HeaderBar from './header-bar/header-bar';
import NavigationMenu from './sidebar/navigation-menu';
import Badge from './containers/Badge';

import LawsuitService from '../services/LawsuitService';
import Select from 'react-select';

import tribunalTypes from './json/tribunalTypes';

import TribunalService from '../services/TribunalService';
import AuthService from '../services/AuthService';

import { Link } from 'react-router-dom';
import 'antd/dist/antd.css';
import { Tabs } from 'antd';
import { Divider } from 'antd';
import Popover, { ArrowContainer } from 'react-tiny-popover';

import Footer from "./containers/footer";
import FlashMessage from "./containers/FlashMessage";
import MyPopover from './containers/mypopover';

import Loading from './containers/loading';
import Alert from './containers/alert';
import Confirm from './containers/confirm';

import img_support from '../image/img_support.svg';

export default class Support extends React.Component { 
  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
    document.body.classList.remove('gestaoprev_private');
  }

  componentDidMount( ) { 
        this._isMounted = true;  
        document.body.classList.add('gestaoprev_private');

  }

  constructor( { match } ) {
      super();
      this.lawsuitService = new LawsuitService();
      this.authService = new AuthService();

      document.body.classList.add('gestaoprev_private');

      this.back = this.back.bind(this);

  }

  componentWillMount( ) {

  }


  state = {
    value: ''
  }

  back(){
    this.props.history.goBack();
  }

  render() {

      let url_base = localStorage.getItem('url_base');
      const loggedUser = JSON.parse(localStorage.getItem('loggedUser'));


      return <div className="sheet">
                <FlashMessage time={ new Date().getTime() } />
                <HeaderBar />
                <NavigationMenu />
                <div className="content-wrapper">
                  <h1>Suporte</h1>
                  <div className='screen-empty'>
                      <object type="image/svg+xml" data={img_support} width="260" height="260"></object>
                      <span className='subtitle'>Está com alguma dúvida ou problema? Nós estamos aqui para te ajudar</span>
                      <span>Basta entrar em contato com algum de nossos atendentes clicando no botão abaixo:</span>
                      <span className="btn btn-primary form-submit">
                            ENTRAR EM CONTATO
                      </span>
                  </div>
                </div>
             </div>
  }

}