import React from 'react';
import Header from "./containers/header";
import Footer from "./containers/footer";
import Alert from './containers/alert';
import { Link } from 'react-router-dom';
import FlashMessage from "./containers/FlashMessage";

import AuthService from '../services/AuthService';
import OfficeService from '../services/OfficeService';
import './Login.css';
import logo from '../image/logo.svg';
import img_login_page from '../image/login.svg';

export default class ForgotPassword extends React.Component { 
  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
    document.body.classList.remove('login');
  }


  constructor() {
      super();
      this.handleChange = this.handleChange.bind(this);
      this.handleFormSubmit = this.handleFormSubmit.bind(this);
      this.validateForm = this.validateForm.bind(this);
      this.Auth = new AuthService();
      this.OfficeService = new OfficeService();
      document.body.classList.add('login');

      this.state = {
        email: "",
        errors: {},
        success: "",
        message: "",
        showAlert: false
      };

      window.scrollTo(0,0);

  }

  componentWillMount(){
    if(this.Auth.loggedIn())
        this.props.history.replace('/');
  }

  componentDidMount(){
    var url_string = window.location.href;
    var url = new URL(url_string);
    var token = url.searchParams.get("token");
    document.body.classList.add('login');

    document.addEventListener("cancelAlert", (e) => {
      if( this._isMounted ){
        this.setState({
          showAlert: false
        });
      }
    });

    if( token !== null){
        this.OfficeService.confirm(token).then(res =>{
              if( res.success === true ){
                  alert(res.message);

              }else{
                let errors = {};
                errors["email"] = res.message;

                setTimeout(() => {

                  this.setState ({
                      errors: errors
                  });

                }, 300);
              }
        });
    }

  }

  validateForm(){
    let response = false; let errors = {};

    //console.log(this.state.email);

    if( this.state.email === "" || !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email)  ){
      errors["email"] = "*Formato de e-mail inválido.";
    }else{
      response = true;
    }

    this.setState({
       errors: errors
     });

    ////console.log(response);

    return response;
  }


  handleFormSubmit(e){
       e.preventDefault();

       if(this.validateForm()){
               this.Auth.forgotPassword(this.state.email)
                   .then(res =>{
                    //console.log(res);
                      if(res.success === true){
                        alert(res.message);
                        this.setState({
                           success: res.message
                         });
                      }else{
                        let errors = {};
                        errors["email"] = res.message;
                        this.setState({
                          errors
                        });
                      }
                   })
                   .catch(err =>{
                       alert(err);
                   })
       }

   }

   handleChange(e){
        this.setState(
            {
                [e.target.name]: e.target.value
            }
        )
    }


  render() {

      var url_base = localStorage.getItem('url_base');

      if(this.state.success === ""){
            return <div id="login">
                        <FlashMessage time={ new Date().getTime() } />
                        <div className="bg page-login">
                            <div className="left-panel">
                                <a className="logo" href="/">
                                  <img src={logo} width="43" height="48"></img>
                                  <object data={url_base+"/images/gestaoprevi.svg"} width="234" height="62"></object>
                                </a>
                                <span className="login-title">O Número #1 dos</span>
                                <span className="login-title">Advogados Previdenciários</span>
                                <img  alt="nosso logo - avvocato" src={img_login_page} width="424" height="283"></img>
                            </div>
                            <div className="form-login">
                                <form>
                                    <h1>Esqueceu sua senha?</h1>
                                    <span id="emailHelp" className="form-text">Sem problemas! Nós encaminharemos um e-mail com as instruções para que você possa redefinir sua senha.</span>

                                    <label htmlFor="nome" className="">E-mail</label>
                                    <input
                                        className="form-control form-item"
                                        placeholder="Digite seu e-mail"
                                        name="email"
                                        type="text"
                                        onChange={this.handleChange}
                                    />
                                    <div className="errorMsg">{this.state.errors.email}</div>
                                    
                                    <div className="isoInputWrapper isoLeftRightComponent">
                                          <input
                                              id= "submit"
                                              className="btn btn-primary form-submit"
                                              value="Recuperar Senha"
                                              type="submit"
                                              onClick={this.handleFormSubmit.bind(this)}
                                          />
                                    </div>
                                    <Link className="link-forgot" to="/login">
                                    Voltar para seção de login
                                    </Link>
                                    
                                </form>
                            </div>
                        </div>
                   </div>
      }else{
        return <div id="login">
                    <FlashMessage time={ new Date().getTime() } />
                    <div className="bg page-login">
                        <div className="left-panel">
                            <a className="logo" href="/">
                              <img src={logo} width="43" height="48"></img>
                              <object data={url_base+"/images/gestaoprevi.svg"} width="234" height="62"></object>
                            </a>
                            <span className="login-title">O Número #1 dos</span>
                            <span className="login-title">Advogados Previdenciários</span>
                            <img  alt="nosso logo - avvocato" src={img_login_page} width="424" height="283"></img>
                        </div>
                        <div className="form-login">
                            <h2>{this.state.success}</h2>
                        </div>
                    </div>
               </div>
      }
  }

}
