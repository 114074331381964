import React from 'react';
import './App.css';
import PublicRoutes from "./router";

import { Provider } from 'react-redux';

import store from './store';

import Footer from './components/containers/footer';

//localStorage.setItem('url_base', "http://18.230.128.50/gestaoprev_html");
localStorage.setItem('url_base', "https://gestaoprev.dfoli.com.br");
//localStorage.setItem('url_base', "http://localhost:3000");
//localStorage.setItem('url_base', "http://localhost/gestaoprev_html");

const App = () => (
    <Provider store={store}>
            <PublicRoutes history={"hello"} />
            <Footer />
    </Provider>
);

export default App;
