import { delay } from 'redux-saga';
import { takeLatest, put, call, select, all } from 'redux-saga/effects';

import EventService from '../services/EventService';
import moment from "moment";
//import ptBR from 'antd/lib/locale-provider/pt_BR';
import 'moment/locale/pt-br';

moment.locale('pt-BR');

function apiGet() {

    let eventService = new EventService();

    let start = moment().startOf('day').subtract(180, 'days').format('YYYY-MM-DD');
    let end = moment().endOf('day').add(180, 'days').format('YYYY-MM-DD');

    let user = JSON.parse( localStorage.getItem( 'loggedUser' ) );

    return eventService.listNotifications( start, end, user.token );
        /*return new Promise((resolve, reject) => {
            setTimeout(() => {
            resolve([
                { id: 1, text: 'Fazer café' },
                { id: 2, text: 'Fazer café 2' },
                { id: 3, text: 'Fazer café 3' },
                { id: 4, text: 'Fazer café 4' },
            ]);
            }, 2000);
        });*/
}

function* getNotificationsList() {
  /*try {
    var response = yield call(apiGet);

    if(!Array.isArray(response) ) {
        response = [];
    }

    console.log("redux notification", response);

    yield put({ type: 'SUCCESS_NOTIFICATIONS', payload: { data: response } });
  } catch (err) {
      //yield//console.log(err);
    yield put({ type: 'FAILURE_NOTIFICATIONS' });
  }*/
}

export default function* root() {
  yield all([
    takeLatest('REQUEST_NOTIFICATIONS', getNotificationsList),
  ]);
}