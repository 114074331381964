import React from 'react';
import { Link } from 'react-router-dom';
import UsersService from '../../services/UsersService';
import LevelService from '../../services/LevelsService';

import moment from "moment";
import 'moment/locale/pt-br';

import { Progress } from 'antd';
import {  Radio  } from 'antd';

export default class Users extends React.Component { 
  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }

  constructor( props ) {
    super(props);    

    this.onChangePermissionsDelete = this.onChangePermissionsDelete.bind(this);

    this.state = {
        users: [],
        token: "",
        loadingMessage: [],
        loadingVisible: false,
        showModal: false,
        showConfirm: false,
        userToDelete: false,
        message: "",
        changeUser: false,
        usersListDelete: [],
        permissions_delete: 1
    }

  }

  componentDidMount( ) { 
    this._isMounted = true; 

    this.getUsers(  );
  }

    getUsers(){
        this.UsersService = new UsersService();

        this.UsersService.usersList().then(usersList =>{

            if (this._isMounted) {

                    this.levelService = new LevelService();

                    this.levelService.usersPermissionsList( this.state.user_token ).then(res =>{

                        if (this._isMounted) {

                            usersList.map( (user, i, users) => {

                                res.map( (perma, j, array) => {

                                    if(perma.user.token === user.token){
                                        perma.user.last_login = ( user.last_login !== null ) ? moment(user.last_login).format("DD/MM/YYYY HH:mm") : "";
                                        perma.user.active = user.active;
                                        perma.user.admin = user.admin;
                                        perma.user.email = user.email;
                                    }

                                    return perma;

                                });

                                return user;

                            });

                            let usersListDelete = res.map( (user)=>{
                            return {"key": user.user.token, "label": user.user.name,"value": user.user.token}
                            });

                            this.setState({
                            users: res,
                            usersListDelete,
                            changeToUser: usersListDelete[0]
                            });

                        }

                    });

            }

        });
    }

    onChangePermissionsDelete (e) {
        this.setState({
            permissions_delete : e.target.value
        })
    }  


  userItem = ( {record, index}, type ) => {
        /*let category = "";*/ let user_admin = "";

        let access_permissions = null;
        let not_permission =    <span>
                                    Permissões
                                </span>;
        let have_permission = <Link to={"/config/user/permissions/"+record.user.token}>
                                    Permissões
                              </Link>;
        let lixeira_enabled = <span data-token={record.user.token} onClick={ ( event ) => {this.deleteUser(event, false)} }>Excluir</span>;
        let lixeira_disabled = <span>Excluir</span>;
        let lixeira = null;

        let editar_enabled = <Link to={"/config/user/edit/"+record.user.token}>
                                    Editar
                             </Link>;
        let editar_disabled = <span>Editar</span>;
        let editar = null;

        if(record.user.admin === true ){
            access_permissions = not_permission;
            lixeira = lixeira_disabled;
            editar = editar_disabled;
            user_admin = " (Admin)";
        }else{
            access_permissions = have_permission;
            lixeira = lixeira_enabled;
            editar = editar_enabled;
        }

        if( JSON.parse(localStorage.getItem('loggedUser')).token === record.user.token ){
            editar = editar_enabled;
        }

        //console.log(record);

        return (
            <tr key={record.user.token} className={type}>
                <td className="user" >
                    <p>{record.user.name}</p>
                    <p>{record.user.email}</p>
                </td>
                <td className=" hidden-xs hidden-sm" >
                    {(record.user.admin === true ) ? "Sim" : "Não"}
                </td>
                <td className=" hidden-xs hidden-sm" >
                {(record.financeiro[0].allowed) ? "Com permissão" : "Sem permissão"}
                </td>
                <td className=" hidden-xs hidden-sm" >
                        {(record.gerenciar_doc_facil[0].allowed) ? "Com permissão" : "Sem permissão"}
                </td>
                <td className=" hidden-xs hidden-sm" >
                        {(record.user.active) ? "Ativo" : "Inativo"}
                </td>
                <td id={record.user.token+"-liberado"} className=" last hidden-xs hidden-sm" >
                        {record.user.last_login}
                </td>
                <td className="actions hidden-xs hidden-sm" >
                    <i className='material-icons more_vert' data-toggle="dropdown">more_vert</i>
                    <ul className="dropdown-menu dropdown">
                          <li >
                              <div>
                                  {editar}
                              </div>
                          </li>
                          <li >
                              <div>
                                  {access_permissions}
                              </div>
                          </li>
                          <li >
                              <div>
                                  {lixeira}
                              </div>
                          </li>
                      </ul>
                </td>

            </tr>


        );
   };

  render() {
    console.log('users: ', this.state.users);

      return <div className="list-user form">
                <div className='title-line'>
                    <h2>Usuários</h2>
                    <Link className="btn-simple" to="/">
                    Adicionar Usuário
                    </Link>
                </div>
                <div className="box-body">
                    <div className='tips'>
                        <p>Adicione quantos usuários desejar para fazer a gestão do seu escritório previdenciário.</p>
                        <span>Defina as permissões de acordo com o que achar melhor.</span>
                    </div>
                    <table>
                            <thead>
                                <tr>
                                    <th className="table_user">
                                        <div>NOME/EMAIL</div>
                                    </th>
                                    <th className="table_config hidden-xs hidden-sm">
                                        <div>
                                                ADMIN
                                        </div>
                                    </th>
                                    <th className="table_finance hidden-xs hidden-sm">
                                        <div>
                                               FINANCEIRO
                                        </div>
                                    </th>
                                    <th className="table_docs hidden-xs hidden-sm">
                                        <div>
                                                <p>DOCFÁCIL</p>
                                        </div>
                                    </th>
                                    <th className="table_status hidden-xs hidden-sm">
                                        <div>
                                                <p>STATUS</p>
                                        </div>
                                    </th>
                                    <th className="table_last">
                                        <div>
                                                ÚLTIMO ACESSO
                                        </div>
                                    </th>
                                    <th className="table_actions">
                                        <div>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                { ( typeof this.state.users !== "undefined") ? this.state.users.map( (record, index) => this.userItem( {record, index}, 'user-item' ) ) : null }
                            </tbody>
                    </table>
                    <div className='permissions-delete-container'>
                        <span className="subtitle">Permissão para excluir processos jurídicos</span>
                        <Radio.Group onChange={this.onChangePermissionsDelete} value={this.state.permissions_delete}>
                            <Radio value={1}>Somente quem criou o processo</Radio>
                            <Radio value={2}>Todos possuem permissão</Radio>
                        </Radio.Group>
                    </div>
                </div>
             </div>
  }

}
