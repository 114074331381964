import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as NotificationsActions from '../../actions/notifications';

class Footer extends Component {
  constructor(props){
    super(props);
  }
  render() {
    //var url_base = localStorage.getItem('url_base');
    /*return (
            <div id="footer">
                <div id="tiideal-logo">
                    <a href="http://www.dfoli.com.br" target="_blank" rel="noopener noreferrer"><img alt="dfoli" src={url_base+"/images/dfoli.png"} /></a>
                    <span>© Todos os direitos reservados.</span>
                </div>
                <div id="suporte-rodape"><small>Suporte: (11) 4324-8777 - Das 09:00 as 18:00 Hs de segunda a sexta - <a href="mailto:suporte@sistemaavvocato.com.br">suporte@sistemaavvocato.com.br</a></small></div>
                <span><small>Versão 0.5.0 - geração de documentos</small></span>
            </div>
    );*/

    setTimeout(() => {

      window.location.reload(true);

    }, 7200000);  //reload no sistema a cada 2h pra pegar atualizações do app

    //setInterval(() => {
      //this.props.requestNotifications()
    //}, 600000);
    return (
          <span className="new-footer"></span>
    );
  }
}


const mapDispatchToProps = dispatch =>
bindActionCreators(NotificationsActions, dispatch);

export default connect(null, mapDispatchToProps)(Footer);