import AuthService from './AuthService';
import {api} from '../config';
import moment from "moment";

export default class UsersService {
    // Initializing important variables
    constructor(domain) {
        this.domain = domain || api.url // API server domain
        this.fetch = this.fetch.bind(this) // React binding stuff
        this.list = this.list.bind(this)
    }

    active( user_token ){
      return this.fetch(`${this.domain}Users/active/${user_token}`, {
          method: 'POST',
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    add( user, user_token ){
      if( user_token !== null ){
        return this.fetch(`${this.domain}Users/edit/${user_token}`, {
            method: 'POST',
            body: JSON.stringify(user)
        }).then(res => {
            //console.log(res);
            return Promise.resolve(res);
        })
      }else{
        return this.fetch(`${this.domain}Users/create`, {
            method: 'POST',
            body: JSON.stringify(user)
        }).then(res => {
            //console.log(res);
            return Promise.resolve(res);
        })
      }

    }

    addRuleTimes( data, user_token ){
      return this.fetch(`${this.domain}RuleTimes/add/${user_token}`, {
          method: 'POST',
          body: JSON.stringify(data)
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    delete ( id ){
      return this.fetch(`${this.domain}Users/delete/${id}`, {
          method: 'DELETE'
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      })
    }

    changeUser(users) {
      return this.fetch(`${this.domain}Users/changeUser/`, {
          method: 'POST',
          body: JSON.stringify(users)
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res);
      });
    }

    list ( ) {

      let usersList = JSON.parse(localStorage.getItem('usersList'));
      if( 
        ( usersList == null || 
          usersList == 0 || 
          usersList == "undefined" ||
          typeof usersList.date == "undefined" 
        ) || 
          moment().diff(usersList.date, 'minutes',true ) > 120 
      ){

        return this.fetch(`${this.domain}Users/index/?list=true`, {
            method: 'GET',
        }).then(res => {
            let response = [];
            //console.log(res);
            if( res.success === true ){
              var i = 0;
              res.data.Users.map((value, index, array )=>{
                //let name = (value.name.length > 12) ? value.name.substring(0,12)+"..." : value.name;
                let name = value.name;
                response[index] = { "key": array.length-i, "value": value.token, "label": name, "admin": value.admin };
                i++;
                return response;
              });
            }

            localStorage.setItem('usersList', JSON.stringify({ users: response, date: moment() } ) );
            //console.log(response);
            return Promise.resolve(response);
        });

      }else{
        return Promise.resolve(usersList.users);
      }

    }

    officesList () {
      return this.fetch(`${this.domain}Offices/index/`, {
          method: 'GET',
      }).then(res => {
          //console.log(res);
          let response = [];
          var i = 0;
          res.data.Offices.map((value, index, array )=>{
            response[index] = { "key": array.length-i, "value": value.token, "label": value.name };
            i++;
            return response;
          });

          return Promise.resolve(response);
      });
    }

    usersList ( ) {
      return this.fetch(`${this.domain}Users/index/?list=true`, {
          method: 'GET',
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res.data.Users);
      })
    }

    view ( user_token ) {
      return this.fetch(`${this.domain}Users/view/${user_token}`, {
          method: 'GET',
      }).then(res => {
          //console.log(res);
          if(res.success){
            return Promise.resolve(res.data.Users);
          }else{
            return Promise.resolve(null);
          }
      })
    }

    viewRuleTimes( user_token ){
      return this.fetch(`${this.domain}RuleTimes/index/${user_token}`, {
          method: 'GET',
      }).then(res => {
          //console.log(res);
          return Promise.resolve(res.data.RuleTimes);
      })
    }

    fetch(url, options) {
          let auth = new AuthService();

          options.headers = {
             'Authorization': 'Bearer ' + auth.getToken(),
             'Content-Type': 'application/json',
             'Accept': 'application/json'
           }

          if(localStorage.getItem("id_token") == null || localStorage.getItem("id_token") == ""){
            return Promise.resolve({
              message: "Você não tem permissão para acessar o sistema neste momento."
            });
          }else{
    

            return fetch(url, options)
                  .then(this._checkStatus)
                  .then(response => {

                            if(typeof response !== "undefined"){

                              return Promise.resolve( response.text().then(function(result){
                                  //console.log(result);
                                  let old_result = result;
                                  result = result.split('{"success"');
                                  if( result.length > 1 ){
                                      result = result[1].split('<pre');
                                      //console.log(result[0]);
                                      let json = '{"success"'+result[0];
                                      result = JSON.parse(json);
                                      //console.log(result);
                                      return result;
                                  }else{
                                      return old_result;
                                  }

                              }) );

                          }else{

                              let result = {
                                  success: false,
                                  message: "Erro desconhecido"
                              }

                              return Promise.resolve( result );

                          }

                  });

          }
    }

    _checkStatus(response) {
        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
            return response
        } else if( response.status === 401 || response.status === "401" ) {
         //console.log("Tentando trapacear? Você será desligado");


          localStorage.removeItem('id_token');
          localStorage.removeItem('loggedUser');
          window.location.href = '/login';

        }else {
            /*var error = new Error(response.statusText);
            error.response = response;
           //console.log(response.text());
            throw error*/
            return response
        }
    }

    /*fetch(url, options) {
        // performs api calls sending the required authentication headers
        let auth = new AuthService();

        options.headers = {
           'Authorization': 'Bearer ' + auth.getToken(),
           'Content-Type': 'application/json',
           'Accept': 'application/json'
         }

        return fetch(url, options)
            .then(this._checkStatus)
            .then(response => response.json())
    }*/
}
