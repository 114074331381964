import React from 'react';
import { Tabs } from 'antd';
import HeaderBar from './header-bar/header-bar';
import NavigationMenu from './sidebar/navigation-menu';
import Badge from './containers/Badge';

import LawsuitService from '../services/LawsuitService';
import Select from 'react-select';

import tribunalTypes from './json/tribunalTypes';

import TribunalService from '../services/TribunalService';
import AuthService from '../services/AuthService';

import { Link } from 'react-router-dom';
import 'antd/dist/antd.css';
import { Divider } from 'antd';
import Popover, { ArrowContainer } from 'react-tiny-popover';

import Footer from "./containers/footer";
import FlashMessage from "./containers/FlashMessage";
import Office from "./config/office";
import Users from "./config/users";
import MyPopover from './containers/mypopover';

import Loading from './containers/loading';
import Alert from './containers/alert';
import Confirm from './containers/confirm';

import img_lawsuit from '../image/img_lawsuit.svg';

const TabPane = Tabs.TabPane;

export default class Config extends React.Component { 
  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
    document.body.classList.remove('gestaoprev_private');
  }

  componentDidMount( ) { 
        this._isMounted = true;  
        document.body.classList.add('gestaoprev_private');

  }

  constructor( { match } ) {
      super();

  }

  componentWillMount( ) {

  }


  state = {
    value: ''
  }

  back(){
    this.props.history.goBack();
  }

  render() {

      let url_base = localStorage.getItem('url_base');
      const loggedUser = JSON.parse(localStorage.getItem('loggedUser'));


      return <div className="sheet">
                <FlashMessage time={ new Date().getTime() } />
                <HeaderBar />
                <NavigationMenu />
                <div className="content-wrapper">
                  <h1>Configurações</h1>
                  <Tabs defaultActiveKey="1" size="large">
                        <TabPane tab={<span>Escritório</span>} key="1">
                          <Office />
                        </TabPane>
                        <TabPane tab={<span>Usuários</span>} key="2">
                          <Users />
                        </TabPane>
                        <TabPane tab={<span>Marca</span>}  key="3">
                        </TabPane>
                  </Tabs>
                </div>
             </div>
  }

}