import React from 'react';
import options from '../json/menu_options';
import { Link } from 'react-router-dom';


import LevelService from '../../services/LevelsService';
import AuthService from '../../services/AuthService';

export default class NavigationMenu extends React.Component { 
  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  constructor( ) {
    super();
    this.pushMenu = this.pushMenu.bind(this);

    this.authService = new AuthService();

    this.state = {
        user_permissions: []
    }
  }

  componentDidMount() {
    /*this._isMounted = true;
    this.levelService = new LevelService();

    const loggedUser = JSON.parse(localStorage.getItem('loggedUser'));

    this.levelService.permissionsList( loggedUser.token ).then(permissions =>{

          if( this._isMounted ){

                  //console.log("Usuário logado");
                  //console.log(res);

                  localStorage.setItem('user_permissions', JSON.stringify(permissions) );

                  //const user_permissions = JSON.parse(localStorage.getItem('user_permissions'));

                  this.setState({
                    user_permissions: permissions
                  });

          }

    });*/

  }

  pushMenu( e ) {
        e.preventDefault();

        if ( (" " + document.getElementsByTagName('body')[0].className + " ").replace(/[\n\t]/g, " ").indexOf(" sidebar-collapse ") > -1 ){
          document.getElementsByTagName('body')[0].classList.remove("sidebar-collapse");
        }else{
          document.getElementsByTagName('body')[0].className +=' sidebar-collapse';
        }

  }

  dismiss = ( e ) => {
    e.preventDefault();
  }

  getMenuItem = ({ singleOption }) => {
    const { key, label, leftIcon, children } = singleOption;

    //var tribunals = JSON.parse(localStorage.getItem('tribunals'));
    var escritorio_virtual = localStorage.getItem('escritorio_virtual' );
    if(  escritorio_virtual === true || escritorio_virtual === 1 || escritorio_virtual === "true"  ){
        if (children) {
          let link = "";
          /*if ( key === "tribunal" ) {
                link = <Link to='/active/1'>
                    <i className="material-icons">{leftIcon}</i>
                    <span className="nav-text">
                      <label>{label}</label>
                    </span>
                    <i className="fa fa-angle-left pull-right"></i>
                </Link>
          }else*/ if ( key === "configs" && !this.authService.can('acesso_configuracoes', true) ) {
            return ;

          }else{

            link = <a href="#" onClick={this.dismiss}>
                <i className="material-symbols-outlined">{leftIcon}</i>
                <span className="nav-text">
                  <label>{label}</label>
                </span>
                <i className="fa fa-angle-left pull-right"></i>
            </a>

          }

          return (
            <li key={key} className={ ( this.props.active === "1" && key === "tribunal" ) ? "treeview active" : "treeview" }>
                {link}
                <ul className={ ( this.props.active === "1" && key === "tribunal" ) ? "treeview-menu menu-open" : "treeview-menu" }>

              {children.map(child => {
                const linkTo = `/${child.key}`;

                  if (child.grandchildren) {

                    return (
                      <li key={child.key}>
                          <a href="/">
                              <span className="nav-text">
                                <label>{child.label}</label>
                              </span>
                              <i className="fa fa-angle-left pull-right"></i>
                          </a>
                          <ul className="treeview-menu">
                            {child.grandchildren.map(grandchild => {
                              const linkTo = `/${grandchild.key}`;

                              if (grandchild.grandchildren) {
                                  return (
                                      <li key={grandchild.key}>
                                            <a href="/">
                                                <span className="nav-text">
                                                  <label>{grandchild.label}</label>
                                                </span>
                                                <i className="last-angle fa fa-angle-left pull-right"></i>
                                            </a>
                                            <ul className="treeview-menu">
                                                {grandchild.grandchildren.map(gc => {
                                                    const linkTo = `/${gc.key}`;
                                                    return (
                                                      <li key={gc.key}>
                                                          <Link to={linkTo}>
                                                            <label>{gc.label}</label>
                                                          </Link>
                                                      </li>
                                                    )
                                                  })
                                                }
                                            </ul>
                                      </li>
                                  );
                              }else{
                                return (
                                      <li key={grandchild.key}>
                                            <Link to={linkTo}>
                                                <span className="nav-text">
                                                  <label>{grandchild.label}</label>
                                                </span>
                                            </Link>
                                      </li>
                                )
                              }
                            })}
                          </ul>
                      </li>
                    );
                  }

                  if ( child.key === "gerenciar_modelos" && !this.authService.can('gerenciar_doc_facil', true) ) {
                    return ;
                  }

                  if ( child.key === "financeiro/pagar" && !this.authService.can("financeiro_contas_pagar", true, 'financeiro') ) {
                    return ;
                  }
                  if ( child.key === "financeiro/receber" && !this.authService.can("financeiro_contas_receber", true, 'financeiro') ) {
                    return ;
                  }
                  if ( child.key === "financeiro/fluxo-caixa" && !this.authService.can("financeiro_fluxo_caixa", true, 'financeiro') ) {
                    return ;
                  }

                return (
                    <li key={child.key}>
                        <Link to={linkTo}>
                          <span className="nav-text">
                                  <label>{child.label}</label>
                          </span>
                        </Link>
                    </li>
                );
              })}
              </ul>
            </li>
          );
        }
        return (
          <li key={key}>
              <Link to={`/${key}`}>
                  <i className="material-symbols-outlined">{leftIcon}</i>
                  <span className="nav-text">
                    <label>{label}</label>
                  </span>
              </Link>
          </li>
        );
    }else{
      return null;
    }
  };

  render() {

      var url_base = localStorage.getItem('url_base');

      let user_token = JSON.parse(localStorage.getItem('loggedUser')).token;

      let afiliados_item_menu = null;

      if( localStorage.getItem('afiliado' ) === "true" ){
        afiliados_item_menu = <li id="collapse-menu" className="hide-if-no-js">
            <Link to={`/afiliados`}>
              <i className="fa fa-handshake-o" aria-hidden="true"></i><span className="nav-text"><label>Indicados</label></span>
            </Link>
        </li>
      }

      let inicio_menu_item = null;

      var escritorio_virtual = localStorage.getItem('escritorio_virtual' );
      if(  escritorio_virtual === true || escritorio_virtual === 1 || escritorio_virtual === "true" ){
        inicio_menu_item =  <li id="collapse-menu" className="hide-if-no-js">
                              <a  data-toggle="offcanvas" href={url_base}>
                                <i className="material-symbols-outlined" aria-hidden="true">home</i><span className="nav-text"><label>Dashboard</label></span>
                              </a>
                            </li>
      }

      return (
          <aside className="main-sidebar">

              {/* sidebar: style can be found in sidebar.less */}
              <section className="sidebar" >
                  {/* sidebar menu: : style can be found in sidebar.less */}
                  <ul className="sidebar-menu">
                      {inicio_menu_item}
                      {options.map( singleOption => this.getMenuItem({ singleOption }) )}
                      <div className="separator"></div>
                      <li id="collapse-menu" className="hide-if-no-js">
                        <Link to={`/support`}>
                          <i className="material-symbols-outlined" aria-hidden="true">help</i><span className="nav-text"><label>Suporte</label></span>
                        </Link>
                      </li>
                  </ul>
              </section>
              {/* /.sidebar */}
          </aside>
      )
  }
}
