import React from 'react';
import Header from "./containers/header";
import Footer from "./containers/footer";
import Alert from './containers/alert';
import { Link } from 'react-router-dom';
import AuthService from '../services/AuthService';
import './Login.css';
import logo from '../image/logo.svg';
import check from '../image/check.svg';
import img_login_page from '../image/login.svg';

export default class SignUp extends React.Component { 
    _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
    document.body.classList.remove('login');
  }

  componentDidMount( ) { 
        this._isMounted = true;
        document.body.classList.add('login');

        document.addEventListener("cancelAlert", (e) => {
            this.setState({
            showAlert: false
            });
        });
  }

  constructor() {
      super();
      this.handleChange = this.handleChange.bind(this);
      this.handleFormSubmit = this.handleFormSubmit.bind(this);
      this.Auth = new AuthService();
      document.body.classList.add('login');

      this.state = {
        message: "",
        showAlert: false
      }

      window.scrollTo(0,0);

  }

  componentWillMount(){
    if(this.Auth.loggedIn())
        this.props.history.replace('/');
  }


  handleFormSubmit(e){
       e.preventDefault();

       if (!this.state.name || !this.state.email || !this.state.password || !this.state.password_confirmation || !this.state.city || !this.state.uf ) {
         alert( 'Favor Preencha os campos abaixo!' );
         /*this.setState({
            showAlert: true,
            message: 'Favor Preencha os campos abaixo!'
          });*/
       }else if ( this.state.password !== this.state.password_confirmation ) {
         alert( 'Senha e confirmação não conferem!' );
         /*this.setState({
            showAlert: true,
            message: 'Senha e confirmação não conferem!'
          });*/
       }else{

           this.Auth.register(this.state.name, this.state.email, this.state.password, this.state.city, this.state.uf)
               .then(res =>{
                  this.props.history.replace('/');
               })
               .catch(err =>{
                   alert(err);
                   /*this.setState({
                    showAlert: true,
                    message: err
                  });*/
               })

      }
   }

   handleChange(e){
        this.setState(
            {
                [e.target.name]: e.target.value
            }
        )
    }


  render() {
        var url_base = localStorage.getItem('url_base');
      return <div id="login">

                  <Alert show={this.state.showAlert} text={this.state.message} />
                  <div className="bg page-login">
                      <div className="left-panel">
                          <a className="logo" href="/">
                            <img src={logo} width="43" height="48"></img>
                            <object data={url_base+"/images/gestaoprevi.svg"} width="234" height="62"></object>
                          </a>
                          <span className="signup-title">Junte-se a mais de</span>
                          <span className="signup-title">1000 advogados previdenciários</span>

                          <div className='signup-box'>
                            <span className="signup-subtitle">Crie sua conta e tenha acesso instantâneo a:</span>
                            <ul>
                                <li><img src={check} /><span>Processos Ilimitados</span></li>
                                <li><img src={check} /><span>Processos Monitorados</span></li>
                                <li><img src={check} /><span>Armazenamento</span></li>
                                <li><img src={check} /><span>Doc Fácil</span></li>
                                <li><img src={check} /><span>Controle Financeiro</span></li>
                                <li><img src={check} /><span>Agenda</span></li>
                            </ul>
                          </div>
                      </div>
                      <div className="form-login">
                          <form>
                              <h1 className="signup-form-title">Criar conta grátis</h1>
                              <label htmlFor="nome" className="">Nome Completo</label>
                              <input
                                  className="form-control form-item"
                                  placeholder="Digite seu nome completo"
                                  name="name"
                                  type="text"
                                  onChange={this.handleChange}
                              />
                              <label htmlFor="nome" className="">Telefone ou celular</label>
                              <input
                                  className="form-control form-item"
                                  placeholder="(00) 0000-0000"
                                  name="name"
                                  type="text"
                                  onChange={this.handleChange}
                              />
                              <label htmlFor="email" className="">E-mail</label>
                              <input
                                  className="form-control form-item"
                                  placeholder="Digite seu e-mail comercial"
                                  name="email"
                                  type="text"
                                  onChange={this.handleChange}
                              />
                              <label htmlFor="password">Senha</label>
                              <input
                                  className="form-control form-item"
                                  placeholder="Dica: digite uma senha com mais de 6 caracteres"
                                  name="password"
                                  type="password"
                                  onChange={this.handleChange}
                              />
                              <span className="center disclaimer">Confirmando seu cadastro, você declara que leu e aceitou: os Termos de Uso e a Política de Privacidade do GestãoPrev, além da Política de Privacidade dos nossos sites.</span>
                              <div className="isoInputWrapper isoLeftRightComponent">
                                    <input
                                        id= "submit"
                                        className="btn btn-primary form-submit"
                                        value="Criar conta GRÁTIS"
                                        type="submit"
                                        onClick={this.handleFormSubmit.bind(this)}
                                    />
                              </div>
                            <Link className="link-forgot" to="/login">
                            Voltar para seção de login
                            </Link>
                              
                          </form>
                      </div>
                  </div>
             </div>
  }

}
