import React from 'react';
import Header from "./containers/header";
import Footer from "./containers/footer";
import Alert from './containers/alert';
import { Link } from 'react-router-dom';
import FlashMessage from "./containers/FlashMessage";

import AuthService from '../services/AuthService';
import OfficeService from '../services/OfficeService';
import './Login.css';
import logo from '../image/logo.svg';
import img_login_page from '../image/login.svg';

export default class ForgotPasswordConfirm extends React.Component { _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }


  constructor() {
      super();
      this.handleChange = this.handleChange.bind(this);
      this.handleFormSubmit = this.handleFormSubmit.bind(this);
      this.validateForm = this.validateForm.bind(this);
      this.Auth = new AuthService();
      this.OfficeService = new OfficeService();

      this.state = {
        password: "",
        password2: "",
        errors: {},
        success: "",
        message: "",
        showAlert: false
      };

      window.scrollTo(0,0);

  }

  componentWillMount(){
    if(this.Auth.loggedIn())
        this.props.history.replace('/');
  }

  componentDidMount(){
    var url_string = window.location.href;
    var url = new URL(url_string);
    var token = url.searchParams.get("token");
    document.body.classList.add('login');

    document.addEventListener("cancelAlert", (e) => {
        this.setState({
        showAlert: false
        });
    });

    if( token !== null){
        this.Auth.forgotPasswordConfirm(token).then(res =>{
              if( res.success === true && res.data.jwt !== "" ){

                  setTimeout(() => {

                    this.setState ({
                        update: 1,
                        token: res.data.token,
                        jwt: res.data.jwt
                    });

                  }, 300);

              }else{
                localStorage.setItem('alert_message', res.message);
                localStorage.setItem('alert_type', 'alert-danger');
                let time = new Date().getTime();
                localStorage.setItem('alert_time', time );
                setTimeout(() => {

                  this.setState ({
                      update: 2
                  });

                }, 300);
              }
        });
    }

  }

  validateForm(){
    let response = false; let errors = {};

    //console.log(this.state.email);

    if( this.state.password === "" || (this.state.password !== this.state.password2 )  ){
      errors["password"] = "*Senha não confere";
    }else{
      response = true;
    }

    this.setState({
       errors: errors
     });

    ////console.log(response);

    return response;
  }


  handleFormSubmit(e){
       e.preventDefault();

       if(this.validateForm()){
               this.Auth.changePassword(this.state.password, this.state.password2, this.state.jwt)
                   .then(res =>{
                    //console.log(res);
                      if(res.success === true){
                        //alert(res.message+"\n Você será redirecionado para o login");
                        this.setState({
                          showAlert: true,
                          message: res.message+"\n Você será redirecionado para o login"
                        });
                        this.props.history.replace('/logout');
                      }
                   })
                   .catch(err =>{
                       alert(err);
                   })
       }

   }

   handleChange(e){
        this.setState(
            {
                [e.target.name]: e.target.value
            }
        )
    }


  render() {

      var url_base = localStorage.getItem('url_base');

      if(this.state.update === 1){
            return <div id="login">
                        <FlashMessage time={ new Date().getTime() } />
                        <Alert show={this.state.showAlert} text={this.state.message} />
                        <div className="bg page-login">
                            <div className="left-panel">
                                <a className="logo" href="/">
                                  <img src={logo} width="43" height="48"></img>
                                  <object data={url_base+"/images/gestaoprevi.svg"} width="234" height="62"></object>
                                </a>
                                <span className="login-title">O Número #1 dos</span>
                                <span className="login-title">Advogados Previdenciários</span>
                                <img  alt="nosso logo - avvocato" src={img_login_page} width="424" height="283"></img>
                            </div>
                            <div className="form-login">
                                <form>
                                    <label htmlFor="password">Senha</label>
                                    <input
                                        className="form-control form-item"
                                        placeholder="Crie sua nova senha"
                                        name="password"
                                        type="password"
                                        onChange={this.handleChange}
                                    />
                                    <label htmlFor="password">Confirme sua Senha</label>
                                    <input
                                        className="form-control form-item"
                                        placeholder="Confirme sua senha"
                                        name="password2"
                                        type="password"
                                        onChange={this.handleChange}
                                    />
                                    <div className="errorMsg">{this.state.errors.password}</div>
                                    <div className="isoInputWrapper isoLeftRightComponent">
                                          <input
                                              className="btn btn-primary form-submit"
                                              value="Redefinir Senha"
                                              type="submit"
                                              onClick={this.handleFormSubmit.bind(this)}
                                          />
                                    </div>
                                    <Link className="link-forgot" to="/login">
                                    Voltar para seção de login
                                    </Link>
                                </form>
                            </div>
                        </div>
                   </div>
      }else{
        return <div id="login">
                    <Header />
                    <FlashMessage time={ new Date().getTime() } />
                    <div className="bg page-login">
                            <div className="left-panel">
                                <a className="logo" href="/">
                                  <img src={logo} width="43" height="48"></img>
                                  <object data={url_base+"/images/gestaoprevi.svg"} width="234" height="62"></object>
                                </a>
                                <span className="login-title">O Número #1 dos</span>
                                <span className="login-title">Advogados Previdenciários</span>
                                <img  alt="nosso logo - avvocato" src={img_login_page} width="424" height="283"></img>
                            </div>
                            <div className="form-login">
                            <h3>Token inválido, verifique seu email, ou clique em esqueci minha senha para reiniciar o processo</h3>

                            <span className="left">
                                    <Link to="/login">
                                    Voltar para seção de login
                                    </Link>
                            </span>

                            <span className="right">
                                    <Link to="/forgotPassword">
                                      Esqueci minha senha
                                    </Link>
                            </span>
                        </div>
                    </div>
               </div>
      }
  }

}
